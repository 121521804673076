/* eslint-disable import/order */
import React, { useMemo } from 'react';

import {
  portfolioDashboardPath,
  multiEntityReportsPath,
  chartOfAccountsPath,
} from '@src/routes';

import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';
import AddNewPortfolio from './add_new_portfolio';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
  businesses: IBusiness[] | IClientBusiness[]
}

const PortfolioDropDownList = ({
  launchDarklyFeature,
  businesses,
}: IServiceMenuProps) => {
  let title: string = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE;

  if (window.location.pathname === portfolioDashboardPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD;
  } else if (window.location.pathname === multiEntityReportsPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS;
  } else if (window.location.pathname === chartOfAccountsPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS;
  }

  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const coasEnabled = useUserFeatureFlag(featureFlag);
  const portfolioResp = useGetPortfolio();

  const portfolioList = useMemo(() => {
    return portfolioResp?.data?.portfolios || [];
  }, [portfolioResp.data]);

  const onCreatePortfolio = () => {
    portfolioResp.refetch();
  };

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        className="font-16 font-bold"
        showSelect={ false }
        title={ title }
      >
        <ServiceItem
          serviceUrl={ portfolioDashboardPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD }
        />
        <ServiceItem
          serviceUrl={ multiEntityReportsPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
        />
        {
          coasEnabled && (
            <ServiceItem
              serviceUrl={ chartOfAccountsPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS }
            />
          )
        }
        {portfolioList.map((portfolio) => {
          return (
            <ServiceItem
              key={ portfolio.name }
              serviceUrl="redirect"
              title={ portfolio.name }
            />
          );
        })}
        <AddNewPortfolio
          businesses={ businesses }
          onCreate={ onCreatePortfolio }
        />
      </ModuleHeaderMenu>
    </div>
  );
};

export default PortfolioDropDownList;
