/* eslint-disable import/order */
import { ICreatePortfolioParams, IPortfolioData } from "@src/types/portfolio";
import { camelizeKeys, underscoreKeys } from "@src/utils/transform_keys";
import { apiGet, apiPost } from "../helpers";

const createPortfolio = (params: ICreatePortfolioParams):
  Promise<IPortfolioData> => {
  return apiPost(
    '/api/v1/portfolios',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IPortfolioData);
};

const getPortfolioList = (): Promise<IPortfolioData> => {
  return apiGet('/api/v1/portfolios')
  .then((data) => camelizeKeys(data) as IPortfolioData);
};

export {
  createPortfolio,
  getPortfolioList
}