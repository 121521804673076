// @ts-nocheck
import React, { useMemo, useCallback, useState } from 'react';

import moment from 'moment';

import { useGetAnalytics, usePutAverageBookkeepingRate } from '@src/hooks/queries/ai_analytics/ai_analytics';

import useMessageModal from '@src/components/common/message_modal/ai_modal';

import { convertTime } from './utils';

import styles from './styles.module.scss';

interface AnalyticsProps {
}

const Analytics: React.FC<AnalyticsProps> = () => {
  const changeRateModal = useMessageModal();
  const confirmRateCalculate = usePutAverageBookkeepingRate();
  const { mutate } = confirmRateCalculate;
  const [rate, setRate] = useState(25);

  const handleRateCalculate = useCallback(() => {
    mutate({
      average_bookkeeping_rate: Number(rate),
    }, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  }, [mutate, rate]);

  const analyticsQuery = useGetAnalytics({ time_frame: 0 });
  const analytics = useMemo(() => {
    return analyticsQuery.data || {};
  }, [analyticsQuery.data]);

  if (Object.keys(analytics).length === 0) {
    return null;
  }

  const {
    aiAnalytic: {
      totalItemsProcessed,
      timeSavings,
      monetarySavings,
      averageBookkeepingRate,
      updatedAt,
    },
  } = analytics;

  const handleRateClick = () => {
    changeRateModal.open();
  };

  const handleRateChange = (event) => {
    const target = event.target;
    setRate(Number(target.value));
  };

  const dateTips = `as of ${moment(updatedAt).format('MMM D YYYY, h:mm A')}`;

  return (
    <>
      {/* <div className={ styles['ai-analytics-title'] }>Overall</div> */}
      <div className={ styles['ai-analytics-date-tips'] }>{ dateTips }</div>
      <ul className={ styles['ai-analytics-list'] }>
        <li>
          <span>
            Total Transactions Categorized by AI
            <span className="icon icon-docyt-support" title="Count of total transactions correctly categorised by Docyt AI" />
          </span>
          <span>{totalItemsProcessed}</span>
        </li>
        <li>
          <span>
            Time Savings
            <span className="icon icon-docyt-support" title="Calculated based on time saved by AI versus manual categorization, considering one day is equivalent to an 8-hour workday." />
          </span>
          <span>{convertTime(timeSavings)}</span>
        </li>
        <li>
          <span>
            Monetary Savings
            <span className="icon icon-docyt-support" title="Calculated based on manual bookkeeping cost on per hour basis" />
          </span>
          <span className={ styles['ai-analytics-list-monetary-savings'] }>
            $
            {monetarySavings}
            <span>
              $
              {Number(averageBookkeepingRate).toFixed(2)}
              /hour
            </span>
            <span className={ styles['ai-analytics-list-modify'] } role="button" tabIndex={ 0 } onClick={ handleRateClick }>
              Modify
            </span>
          </span>
        </li>
      </ul>
      <changeRateModal.Component
        { ...changeRateModal.props }
        title="Hourly Bookkeeping Rate"
        onDone={ handleRateCalculate }
      >
        {() => (
          <div className={ styles['ai-analytics-modal'] }>
            <span>
              Specify an estimated hourly rate for bookkeeping services
            </span>
            <div className={ styles['ai-analytics-rate-wrap'] }>
              <span>Amount per hour</span>
              <div className={ styles['input-wrapper'] }>
                <span className={ styles['dollar-sign'] }>$</span>
                <input type="number" onChange={ handleRateChange } />
              </div>
            </div>
          </div>
        )}
      </changeRateModal.Component>
    </>
  );
};

export default Analytics;
