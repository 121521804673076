import React from 'react';

import Root from '@src/components/root';

import Analytics from './analytics';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainPage: React.FC = () => {
  return (
    <Root>
      <Analytics />
    </Root>
  );
};

export default MainPage;
