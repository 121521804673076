// @ts-nocheck
import React, { useMemo } from 'react';

import { useGetAnalytics } from '@src/hooks/queries/ai_analytics/ai_analytics';
import { aiAnalyticspath } from '@src/routes';

import { convertTime } from '@src/components/ai_analytics/utils';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '@src/components/header/styles.module.scss';

const AIAnalyticsAction: React.FC = () => {
  const currentAdvisor = window.Docyt.currentAdvisor;
  const analyticsQuery = useGetAnalytics({ time_frame: 0 }, currentAdvisor?.canAccessAiAnalytics());
  const analytics = useMemo(() => {
    return analyticsQuery.data || { aiAnalytic: { timeSavings: 0 } };
  }, [analyticsQuery.data]);

  const {
    aiAnalytic: {
      timeSavings,
    },
  } = analytics;

  if (currentAdvisor?.canAccessAiAnalytics()) {
    return (
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href={ aiAnalyticspath() }
      >
        <div className={ styles['ai-analytics-item'] }>
          <span>AI Analytics</span>
          <span>
            {`${convertTime(timeSavings)} saved`}
          </span>
        </div>
      </ActionsDropdown.LinkAction>
    );
  }
  return null;
};

export default AIAnalyticsAction;
