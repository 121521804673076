import { TID, TUUID, TMongoID, TYear } from '@src/types/common';
import { urlWithFilterParam } from '@src/utils/url_helpers';

import { Section } from './constants/sections';

export const dataExportPath = () => '/data_export';
export const multiEntityReportsPath = () => '/multi_entity_reports';
export const journalEntriesPath = (businessId: number) => `/businesses/${businessId}/journal_entries`;
export const aiAnalyticspath = () => '/ai_analytics';
export const myPracticePath = () => '/my_practice';
export const feedbackPath = () => '/feedback';
export const businessPath = (businessId: string) => `/businesses/${businessId}`;
export const businessServicePath = (businessId: string, urlAfterBizId: string) => `/businesses/${businessId}/${urlAfterBizId}`;
export const clientBusinessPath = (businessId: string, clientBusinessId: string) => `/businesses/${businessId}/clients/${clientBusinessId}`;
export const businessHomePath = () => '/dashboard';
export const clientBusinessHomePath = () => '/dashboard/clients';
export const portfolioDashboardPath = () => '/portfolio_dashboard';
export const chartOfAccountsPath = () => '/chart_of_accounts';

export const vendorInvoicesPath = (
  businessId: TID,
  vendorId: TID,
) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/invoices`;

// Documents
export const documentPath = (documentId: TID) => `/document/${documentId}`;
export const relatedDocumentPath = (docytId: string) => `/document/${docytId}`;

// Revenue center
export const revenueServicePath = (businessId: TID) => `/businesses/${businessId}/revenue_service`;
export const revenueServicePathAddSystemPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/add_system`;
export const revenueServicePathRevenueCapturePath = (businessId: TID, revenueSystemId: TUUID) => `/businesses/${businessId}/revenue_service/${revenueSystemId}/revenue_capture`;
export const revenueServicePathDepositTrackingPath = (businessId: TID, revenueSystemId: TUUID) => `/businesses/${businessId}/revenue_service/${revenueSystemId}/deposit_tracking`;
export const revenueServicePathUnassignedPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/unassigned`;
export const revenueServiceSettingsPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/settings`;
export const revenueServiceReportPath = (businessId: TID, revenueServiceDocumentId: TID, tab?: string) => `/businesses/${businessId}/revenue_service/reports/${revenueServiceDocumentId}${tab ? `/${tab}` : ''}`;
export const revenueServiceSettingsReportTypesPath = (businessId: TID, type: 'active' | 'archived' = 'active') => {
  return `/businesses/${businessId}/revenue_service/settings/report_types?type=${type}`;
};
export const revenueServiceResolveReportPath = (businessId: TID, revenueSystemId: TUUID, revenueServiceDocumentId: TID) => `/businesses/${businessId}/revenue_service/${revenueSystemId}/revenue_capture/${revenueServiceDocumentId}/resolve`;

export const editRevenueReportTypePath = (businessId: TID, reportTypeId: TID) => `/businesses/${businessId}/revenue_service/settings/report_types/${reportTypeId}`;
export const revenueServiceReconcilePath = (
  businessId: TID,
  revenueSystemId: TUUID,
  params?: object,
) => {
  return urlWithFilterParam(
    `/businesses/${businessId}/revenue_service/${revenueSystemId}/reconcile`,
    {
      paramName: `filter_${Section.ReconcileRevenue}`,
      params,
    },
  );
};
export const revenueServiceReconcilePaymentProcessorPath = (
  businessId: TID,
  revenueSystemId: TUUID,
  paymentProcessorId: TID,
  { revenueParams, incomeParams }: { revenueParams?: object, incomeParams?: object },
) => {
  return urlWithFilterParam(
    `/businesses/${businessId}/revenue_service/${revenueSystemId}/reconcile/${paymentProcessorId}`,
    {
      paramName: `filter_${Section.ReconcileRevenue}`,
      params:    revenueParams,
    },
    {
      paramName: `filter_${Section.ReconcileIncome}`,
      params:    incomeParams,
    },
  );
};

export const managementGroupDashboardWidgetPath = (
  dashboardId: TMongoID,
  widgetId?: string,
) => {
  if (widgetId) return `/portfolio_dashboard/${dashboardId}?widget_id=${widgetId}`;
  return `/portfolio_dashboard/${dashboardId}`;
};

export const businessDashboardPath = (
  businessId: TID,
  dashboardId: TMongoID,
) => `/businesses/${businessId}/dashboards/${dashboardId}`;

export const businessDashboardWidgetPath = (
  businessId: TID,
  dashboardId: TMongoID,
  widgetId: TMongoID,
) => `/businesses/${businessId}/dashboards/${dashboardId}?widget_id=${widgetId}`;

// Settings
// Settings - Integrations
export const settingsIntegrationsPath = () => '/settings/integrations';
export const settingsIntegrationExternalSystemsPath = (businessId: TID) => `/settings/integrations/${businessId}/external_systems`;

export const rcMonthEndReconciliationPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/month_end_reconciliation`;
// Month-end main table links
export const bankStatementsPath = (
  businessId: TID,
  docytId?: string,
  bankStatementState?: string,
  bankStatementsDocRequestEnabled?: boolean,
) => {
  if (bankStatementsDocRequestEnabled) {
    switch (bankStatementState) {
      case 'uploaded':
        return `/businesses/${businessId}/reconciliation_center/month_end_documents/pending_bank_statements/${docytId}`;
      case 'approved':
      case 'verified':
        return `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements/${docytId}`;
      default:
        return `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`;
    }
  } else {
    switch (bankStatementState) {
      case 'uploaded':
        return `/businesses/${businessId}/banking_accounts/pending_review/${docytId}`;
      case 'approved':
      case 'verified':
        return `/businesses/${businessId}/banking_accounts/reviewed/${docytId}`;
      default:
        return `/businesses/${businessId}/banking_accounts/pending_review`;
    }
  }
};

export const uncategorizedTransactionsPath =
(businessId: TID, paId: TID, startDate: string, endDate: string) => {
  return `/businesses/${businessId}/reconciliation_center/transactions?transaction_date_start=${startDate}&transaction_date_end=${endDate}&reconciliation_payment_account_id=${paId}`;
};

export const bankTransfersPath = (businessId: TID, startDate: string, endDate: string) => {
  return `/businesses/${businessId}/reconciliation_center/bank_transfers?transaction_date_start=${startDate}&transaction_date_end=${endDate}&matched_type=false`;
};

export const unclearedDocumentsPath = (businessId: TID, params: object) => {
  return urlWithFilterParam(
    `/businesses/${businessId}/reconciliation_center/documents`,
    {
      paramName: 'filter_combined',
      params,
    },
  );
};

export const allTransactionsPath = (businessId: TID, params: object) => {
  return urlWithFilterParam(
    `/businesses/${businessId}/reconciliation_center/all_transactions`,
    {
      paramName: 'filter_all_transactions',
      params,
    },
  );
};

// Business Reports
export const basicReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=basic`;
export const revenueReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=revenue`;
export const expenseReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=expense`;
export const laborReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=labor`;
export const managementReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=management`;
export const departmentReportsPath = (businessId: TID) => `/businesses/${businessId}/reports?category=department`;
export const categoryReportsPath = (businessId: TID, category: string) => `/businesses/${businessId}/reports?category=${category}`;
export const budgetsPath = (businessId: TID) => `/businesses/${businessId}/reports/budgets`;
export const viewReportPath = (businessId: TID, slug: string) => `/businesses/${businessId}/reports/${slug}`;
export const editMappingPath = (businessId: TID, slug: string) => `/businesses/${businessId}/reports/${slug}/edit_map`;
export const editMappingLaborPath = (businessId: TID, slug: string) => `/businesses/${businessId}/reports/${slug}/edit_map_labor`;
export const viewReportRefreshLogs = (businessId: TID, reportId: TUUID) => `/businesses/${businessId}/reports/${reportId}/refreshLog`;

// Export Business Reports
export const businessReportExportPath = (businessId: TID, category: string, type: string, slug?: string) => `/businesses/${businessId}/reports/export?category=${category}&type=${type}&slug=${slug}`;
export const multiEntityReportsExportPath = (multiReportId: string) => `/multi_entity_reports/custom_reports/${multiReportId}`;

// Dashboard
export const dashboardPath = (businessId: TID) => `/businesses/${businessId}`;
export const clientBusinessDashboard = (businessId: TID, accountingFirmBusinessId: TID) => `/businesses/${accountingFirmBusinessId}/clients/${businessId}`;

// business settings
export const businessSettingsPath = (businessId: TID) => `/setup-business/${businessId}`;
export const businessSettingsStepPath = (businessId: TID, step: number) => `/setup-business/${businessId}?step=${step}`;

// client Business
export const clientBusinessSettingsPath = (businessId: TID, accountingFirmBusinessId: TID) => `/setup-client-business/${businessId}?accounting_firm_business_id=${accountingFirmBusinessId}`;
export const clientBusinessSettingsStepPath = (businessId: TID, accountingFirmBusinessId: TID, step: number) => `/setup-client-business/${businessId}?accounting_firm_business_id=${accountingFirmBusinessId}&step=${step}`;
export const clientInfoPath = (businessId: TID, accountingFirmBusinessId: TID) => `/businesses/${accountingFirmBusinessId}/clients/${businessId}/details`;

// AP Service
export const accountsPayablePath = (businessId: TID) => `/businesses/${businessId}/accounts_payable`;
export const invoiceQueuePath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/queue?type=queued`;
export const invoiceQueueTabsPath = (businessId: TID, type: string) => `/businesses/${businessId}/accounts_payable/new_queue/${type}`;
export const checkRegisterPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/check_registers`;
export const achTransactionsPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/ach_transactions`;
export const approvalAuditControlsPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/settings/approval_rules`;
export const paymentMethodSetupPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/settings/ach_or_check`;
export const settlementItemsPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/settlement_items?type=settle_queued`;
export const accountsPayablePayeesPath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/payees`;
export const paymentRulesQueuePath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/rules`;
export const accountsPayableSettingsQueuePath = (businessId: TID) => `/businesses/${businessId}/accounts_payable/settings`;

// Receipt Box Service
export const receiptListPath = (businessId: TID) => `/businesses/${businessId}/receipt_box/queue?type=unverified`;
export const receiptBoxSettingsPath = (businessId: TID) => `/businesses/${businessId}/receipt_box/settings`;

// Expense Reports
export const expenseReportsServicePath = (businessId: TID) => `/businesses/${businessId}/expense_reports`;
export const expenseReportsAddExpensePath = (businessId: TID) => `/businesses/${businessId}/expense_reports/add`;
export const expenseReportsSettingsPath = (businessId: TID) => `/businesses/${businessId}/expense_reports/settings`;
export const expenseReportsEmployeeSubmissionsPath = (businessId: TID) => `/businesses/${businessId}/expense_reports/employees`;
export const expenseReportsReimbursementCategoriesPath = (businessId: TID) => `/businesses/${businessId}/expense_reports/category_management`;

// Vendors
export const myVendorsPath = (businessId: TID) => `/businesses/${businessId}/vendor_service`;
export const docytBusinessNetworkPath = (businessId: TID) => `/businesses/${businessId}/vendor_service/docyt_network`;
export const vendors1099ReportsPath = (businessId: TID) => `/businesses/${businessId}/vendor_service/1099_reports`;

// Vendor Service New Routes
export const vendorServicePaymentPath = (businessId: TID, vendorId: TID) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/payments`;
export const vendorServiceProfilePath = (businessId: TID, vendorId: TID) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/profile`;
export const vendorServiceTaxInfoPath = (businessId: TID, vendorId: TID) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/tax-info`;
export const vendorServiceBusinessPath = (businessId: TID, vendorId: TID) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/business`;
export const vendorServiceAuditLogPath = (businessId: TID, vendorId: TID) => `/businesses/${businessId}/vendor_service/payees/${vendorId}/audit-log`;

// Revenue Center
export const revenueCenterPath = (businessId: TID) => `/businesses/${businessId}/revenue_service`;
export const monthlyReportsPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/reports`;
export const dailyReportsPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/reports_daily`;
export const unveirifiedReportsPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/unverified`;
export const revenueCenterSettingsPath = (businessId: TID) => `/businesses/${businessId}/revenue_service/settings`;

// Accounts Receivable
export const arServicePath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service`;
export const arServicePaymentsPath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service/payments`;
export const arServiceCreditMemoPath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service/credit-memos`;
export const arServiceCustomersPath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service/customers`;
export const arServiceVendorProfilePath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service/vendor_profile`;
export const arServiceSettingsPath = (businessId: TID) => `/businesses/${businessId}/accounts_receivable_service/settings`;

// Banking Accounts Service
export const bankingAccountsPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts`;
export const transactionsPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/transactions`;
export const pendingReviewPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/pending_review`;
export const reviewedPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/reviewed`;
export const accountsPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/accounts`;
export const CSVImportPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/transaction_imports`;
export const bankingAccountsTrashPath = (businessId: TID) => `/businesses/${businessId}/banking_accounts/trash`;
export const bankConnectionCenterPath = (businessId: TID) => `/financial_institution_connection_center/${businessId}`;

// Reconciliation Center
export const reconciliationCenterPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center`;
export const rcUncategorizedTransactionsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/transactions`;
export const rcJournalEntriesPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/journal_entries`;
export const rcUnclearedDocumentsPathPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/documents`;
export const rcAllTransactionsPath = (businessId: TID) => `businesses/${businessId}/reconciliation_center/all_transactions`;
export const rcExpensesPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/expenses`;
export const rcRevenuePath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/revenue`;
export const rcBankTransfersPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/bank_transfers`;
export const rcPayrollPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/payroll`;
export const rcEquityPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/equity`;
export const rcLoanPaymentsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/loan_payments`;
export const rcChartOfAccountsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/categories`;
export const rcLedgerSyncingCentrePath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre`;
export const rcSettingsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/settings`;
export const rcMonthEndDocmentsBankStatementsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`;
export const rcMonthEndDocmentsOtherDocumentsPath = (businessId: TID) => `/businesses/${businessId}/reconciliation_center/month_end_documents/other_documents`;
export const rcMonthEndDocmentsOtherDocumentsStatementsPath = (businessId: TID, chartOfAccountId: TID, year?: TYear) => `/businesses/${businessId}/reconciliation_center/month_end_documents/other_documents/${chartOfAccountId}?year=${year}`;
export const rcMonthEndDocmentsOtherDocumentsStatementsDetailsPath = (businessId: TID, chartOfAccountId: TID, year?: TYear, month?: number) => `/businesses/${businessId}/reconciliation_center/month_end_documents/other_documents/${chartOfAccountId}/documents?year=${year}&month=${month}`;

// Metrics Service
export const metricsPath = (businessId: TID) => `/businesses/${businessId}/metrics_service`;
export const laborMetricsPath = (businessId: TID) => `/businesses/${businessId}/metrics_service/labor_metrics`;
export const metricsCSVImportPath = (businessId: TID) => `/businesses/${businessId}/metrics_service/csv_import`;
export const metricsValuePath = (businessId: TID, businessMetricId: TUUID) => `/businesses/${businessId}/metrics_service/${businessMetricId}/values`;
export const forReviewMetricsPath = (businessId: TID) => `/businesses/${businessId}/metrics_service/for_review`;

// Documents
export const businessInboxPath = (businessId: TID) => `/businesses/${businessId}/business_inbox`;
export const vaultTrashPath = (businessId: TID) => `/businesses/${businessId}/business_inbox/trash`;
export const vaultPath = (businessId: TID) => `/businesses/${businessId}/standard_folders`;
export const clientVaultPath = (businessId: TID, accountingFirmBusinessId: TID) => `/businesses/${accountingFirmBusinessId}/clients/${businessId}/details/documents`;

// Open Items
export const flaggedTransactionsPath = (businessId: TID) => `/businesses/${businessId}/open_items/flagged_transactions`;
export const documentRequestsPath = (businessId: TID) => `/businesses/${businessId}/open_items/document_requests`;

// Client Central
export const clientCenteralPath = (businessId: TID) => `/client_central/${businessId}`;
export const clientManagementPath = () => '/settings/client_management';

// self_onboarding
export const selfOnboardingPath = (step: number) => `/self_onboarding?step=${step}`;

// ai_chatbot
export const aiChatBotPath = () => '/ai_chatbot';
